@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Merriweather:wght@700;900&display=swap');

body {
  margin: 0;
  padding: 16px;
  max-width: 50em;
  margin: 0 auto;
  background-color: #eee;
  color: #222;
  font-family: 'Merriweather Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
  font-family: 'Merriweather', serif;
  font-weight: 900;
}

/* Countdown Section */
main h1 {
  font-size: 3em;
}

main p {
  margin-bottom: 0;
  font-size: 0.9em;
}

main h2 {
  margin-top: 0;
  font-size: 2em;
}

main iframe {
  width: 100%;
  height: 50vh;
  border: none;
  border-radius: 8px;
}
/* /Countdown */

/* Separator */
hr {
  border: none;
  padding: 2px;
  background-color: #999;
  max-width: 80%;
  margin: 32px auto;
  border-radius: 1px;
}

/* Q&A */
aside h3 {
  margin-top: 32px;
  margin-bottom: 4px;
  font-weight: 700;
}

aside h3:first-of-type {
  margin-top: 0;
}

aside p {
  margin: 0;
}
/* /Q&A */